<template>
  <div class="change-pwd">
    <div class="back" @click="backToInfo">
      <el-icon>
        <arrow-left-bold />
      </el-icon>
      <span>Back</span>
    </div>
    <form class="inputs" @submit="confirmChange">
      <div class="input">
        <label for="old-pwd">Old Password</label>
        <input type="password" id="old-pwd" v-model="oldPwd" required />
      </div>
      <div class="input">
        <label for="new-pwd-1">New Password</label>
        <input type="password" id="new-pwd-1" v-model="newPwd1" required />
      </div>
      <div class="input">
        <label for="new-pwd-2">New Password Again</label>
        <input type="password" id="new-pwd-2" v-model="newPwd2" required />
      </div>
      <button class="btn" type="submit">Confirm Change</button>
    </form>
  </div>
</template>

<script>
import { userApi } from "../../api/index.js";

export default {
  data() {
    return {
      oldPwd: "",
      newPwd1: "",
      newPwd2: "",
    };
  },
  methods: {
    backToInfo() {
      this.$router.back();
    },
    confirmChange(e) {
      e.preventDefault();
      // 检查密码是否相同
      if (this.newPwd1 != this.newPwd2) {
        this.$store.commit("alertMsg", {
          msg: "Two passwords are different!",
          type: "error",
        });
        return;
      }
      console.log(this.oldPwd, this.newPwd1, this.newPwd2);
      // 发出请求
      userApi
        .changePwd({
          old_password: this.oldPwd,
          new_password: this.newPwd1,
          confirmed_password: this.newPwd2,
        })
        .then((res) => {
          console.log(res);
          if (res.code == 200) {
            this.$store.commit("alertMsg", {
              msg: "Your password is updated",
              type: "ok",
            });
            this.backToInfo();
          } else if (res.code == 4032) {
            this.$store.commit("alertMsg", {
              msg: "Old password is error!",
              type: "error",
            });
          } else if (res.code == 401) {
            this.$store.commit("alertMsg", {
              msg: "Please login first!",
              type: "error",
            });
            this.$router.push({
              path: "/account/sign-in",
              query: { redirect: this.$route.path },
            });
          } else {
            this.$store.commit("alertMsg", {
              msg: res.msg,
              type: "error",
            });
          }
        })
        .catch((err) => {
          this.$store.commit("alertMsg", {
            msg: err,
            type: "error",
          });
        });
    },
  },
};
</script>

<style scoped>
.change-pwd {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
}

.back {
  /* background: pink; */
  align-self: flex-start;
  margin: 50px;
  font-size: 20px;
  cursor: pointer;
}

.inputs {
  display: flex;
  flex-direction: column;
}

.input {
  margin: 10px;
}

.input label {
  display: inline-block;
  font-size: 20px;
  text-align: center;
  width: 200px;
}

.input input {
  height: 30px;
  width: 400px;
  padding: 0 10px;
}

.btn {
  width: fit-content;
  align-self: center;
  font-size: 20px;
  margin: 20px 0;
}
</style>
